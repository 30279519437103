import {ref, watch, computed} from '@vue/composition-api'
import axios from '@axios'
import {useUtils as useI18nUtils} from '@core/libs/i18n'
const { i18n } = useI18nUtils();
function i18nT(str){
	return i18n(str)
};

export default function useBuildingsList() {

    const refBuildingsListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk'},
        {key: 'BuildingAddress', label: i18nT(`Immeuble`), sortable: true},
        {key: 'BuildingSize', label: i18nT(`Superficie`), sortable: true},
        {key: 'PropertiesCount', label: i18nT(`Lots`), sortable: true},
        {key: 'BuildingComments', label: i18nT(`Description`), sortable: true},
        {
			key: 'action',
			label: i18nT(`Actions`),
			thClass: 'tbl-actions',
			tdClass: 'sticky-column',
			stickyColumn: true,
		},
    ]

    const perPage = ref(10)
    const perPageOptions = ref([10, 25, 50, 100])
    const currentPage = ref(1)
    const totalBuildings = ref(0)
    const searchQuery = ref('')
    const sortBy = ref('')
    const isSortDirDesc = ref(true)
    const buildings = ref([])
    const listMode = ref('active')
    const activeBuildingsCount = ref(0);
	const archiveBuildingsCount = ref(0);

    const dataMeta = computed(() => {
        const localItemsCount = refBuildingsListTable.value
        ? refBuildingsListTable.value.localItems.length
        : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBuildings.value
        }

    })

    const refetchData = () => {
        refBuildingsListTable.value.refresh()
    }

    watch(
        [
            currentPage,
            perPage,
            listMode,
            searchQuery
        ],
        () => {
            refetchData()
        }
    )

    const fetchBuildings = (ctx, callback) => {
        const params = {
            page: currentPage.value,
            show_per_page: perPage.value,
            sort_by: sortBy.value,
            sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
            search: searchQuery.value
        }

        if(listMode.value === 'active') {
            params['active'] = 1
        }

        axios
            .get('landlord/buildings', {
                params
            })
            .then(({data}) => {
                console.log("buildingsData: ", data)
                if(data.data.buildings) {
                    buildings.value = data.data.buildings
                    callback(data.data.buildings)
                }
                else {
                    buildings.value = []
                    callback([])
                }

                totalBuildings.value = data.data.pagination.records_count
                activeBuildingsCount.value = data.data['records_count_active']
	            archiveBuildingsCount.value = data.data['records_count_archive']
            })
    }

    return {
        fetchBuildings,
		tableColumns,
		perPage,
		currentPage,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refBuildingsListTable,
		totalBuildings,
		listMode,
		buildings,
        activeBuildingsCount,
        archiveBuildingsCount,
		refetchData,
    }
}