<template>
    <div ref="main-container">
        <b-row class="mb-2">
            <b-col
                cols="12"
                md="4"
                sm="12"
            >
                <h1 class="mb-0">{{ i18nT(`Immeubles`) }}</h1>
            </b-col>
            <b-col
                cols="12"
                md="4"
                sm="12"
            >
                <b-form-group
                >
                    <b-form-radio-group
                        v-model="listMode"
                        button-variant="outline-secondary"
                        name="radio-btn-outline"
                        buttons
                        class="ren-active-buttons"
                    >
                        <b-form-radio value="active">
                            {{ i18nT(`Actifs`) }}
                            <b-badge :variant="listMode == 'active' ? 'light-primary' : 'light-secondary' "
                                     class="ml-50 text-primary">{{ activeBuildingsCount }}
                            </b-badge>
                        </b-form-radio>
                        <b-form-radio value="archive">{{ i18nT(`Archives`) }}
                            <b-badge class="ml-50 text-primary"
                                     :variant="listMode == 'archive' ? 'light-primary' : 'light-secondary'">
                                {{ archiveBuildingsCount }}
                            </b-badge>
                        </b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col
                cols="12"
                md="4"
                sm="12"
            >
                <b-dropdown
                    variant="primary"
                    class="float-right pl-1 pb-1"
                >
                    <template #button-content>
                        <RtIcon variant="light" icon="plus-circle mr-50"/>
                        {{ i18nT(`Nouvel immeuble`) }}
                    </template>

                    <b-dropdown-item class="text-primary"
                                     :to="{ name: 'create-building', params:{tab:'general-info'} }"
                    >
                        <div class=''>
                            <RtIcon variant="light" icon="plus-circle mr-50"/>
                            {{ i18nT(`Nouvel immeuble`) }}
                        </div>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <div>
                            <RtIcon variant="light" icon="file-import mr-50"/>
                            {{ i18nT(`Importer`) }}
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
            </b-col>
        </b-row>
        <b-card no-body>
            <b-row class="mx-2 mt-2">
                <div class="col-12 col-md-7 col-sm 6 mb-1 pl-0">
                    <v-select
                        v-model="perPage"
                        :clearable="false"
                        :options="perPageOptions"
                        class="per-page-selector d-inline-block"
                    />
                    <b-dropdown
                        v-if="selectedItems.length > 0"
                        class="d-inline ml-1"
                        size="md"
                        text="Actions"
                        variant="outline-secondary"
                    >
                        <b-dropdown-item @click="onBulkArchive()">
                                <RtIcon :size="16" variant="light" icon="box-archive"/>
                                <span class="align-middle ml-50">{{ i18nT(`Archiver la sélection`) }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="onBulkDelete()">
                            <span class="text-danger"><RtIcon :size="16" variant="light" icon="trash"/></span>
                            <span
                                class="align-middle ml-50 text-danger"
                            >{{
                                    i18nT(`Supprimer`)
                                }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <!-- search input -->
                <div class="col-12 col-md-5 col-sm 6 mb-1 pr-0 d-flex justify-content-end">
                    <div class="custom-search d-flex justify-content-end">
                        <b-form-group>
                            <div
                                class="input-group input-group-merge"
                                role="group"
                            >
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <RtIcon variant="light" icon="search"/>
                                    </div>
                                </div>
                                <b-form-input
                                    v-model.lazy="searchQuery"
                                    :placeholder="i18nT(`Chercher`)"
                                    class="d-inline-block"
                                    type="text"
                                />
                            </div>
                        </b-form-group>

                    </div>
                </div>
            </b-row>
             <b-table
            ref="refBuildingsListTable"
            v-model="currentItems"
            :key="'BuildingsList'+tableColumns.length"
            :fields="computedColumns"
            :items="fetchBuildings"
            :empty-html="emptyHtml"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
            primary-key="id"
            responsive
            show-empty
            @refreshed="onTableRefresh"
        >
            <template v-slot:head(checkbox)="data">
                <span>{{ data.label.toUpperCase() }}</span>
                <b-form-checkbox
                    v-model="selectAllStatus"
                    class="d-inline-block pl-0"
                    @change="onSelectAll"
                >
                </b-form-checkbox>
            </template>

            <template #head(action)="data">
                <span>{{ data.label.toUpperCase() }}</span>
                <div class="sk-column-control">
                    <b-dropdown
                        no-caret
                        variant="button"
                        toggle-class="p-0"
                        :right="true"
                        class="pr-1 pl-1 d-flex sk-column-filter"
                        v-b-tooltip = "i18nT(`Colonnes`)"
                    >
                        <template #button-content>
                            <RtIcon variant="light" icon="gear text-body" size="18px"/>
                        </template>

                        <div class="pl-1 pr-1" v-for="(field, index) in availableFields" :key="'Fields - ' + index">
                            <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                        </div>
                    </b-dropdown>
                </div>
            </template>

            <template #cell(checkbox)="data">
                <b-form-checkbox
                    v-model="selectedItems"
                    :value="data.item.BuildingID"
                    class="pl-0"
                />
            </template>

            <template #cell(BuildingAddress)="data">
                <b-link class="mb-0 font-weight-bold d-block text-nowrap" :to="{name: 'view-building', params: {id: data.item.BuildingID}}">
                    {{ data.item.BuildingAddress }}
                </b-link>
                <small class="text-secondary">
                    <RtIcon variant="light" icon="location-dot"/>
                    {{ data.item.BuildingActualAddress }}
                    {{ data.item.BuildingActualZip }}
                    {{ data.item.BuildingActualCity }}
                    {{ data.item.BuildingActualCountry }}
                </small>
            </template>

            <template #cell(BuildingSize)="data">
                <span>{{  data.item.BuildingSize ? data.item.BuildingSize : 'N/A' }}</span>
            </template>

            <template #cell(PropertiesCount)="data">
                <div class="d-flex ">
                    <b-link
                        v-if="data.item.BuildingProperties != null"
                        v-for="(property, index) in data.item.BuildingProperties"
                        :key="'property ' + index"
                        :to="{name: 'view-property', params: {id: property.PropertyID}}"
                        class="mr-1 d-flex ren-property cursor-pointer text-nowrap"
                    >
                        {{property.PropertyTitle}}
                    </b-link>
                    <small v-if="data.item.BuildingProperties.length == 0">
                        {{ i18nT(`Pas de biens`) }}
                    </small>
                </div>
            </template>

            <template #cell(description)="data">
                <span>{{  data.item.BuildingComments }}</span>
            </template>

            <template #cell(action)="data">
                <!-- TODO add :to to b-links when vue for detail and edit are set up -->
                <b-link
                    class='text-secondary'
                    v-b-tooltip.hover.top="i18nT(`Modifier`)"
                    :to="{name: 'edit-building', params: {id: data.item.BuildingID}}"
                >
                    <RtIcon variant="light" icon="edit mr-1" :size="15"/>
                </b-link>
                <b-link
                    class='text-secondary'
                    v-b-tooltip.hover.top="i18nT(`Voir`)"
                    :to="{name: 'view-building', params: {id: data.item.BuildingID}}"
                >
                    <RtIcon variant="light" icon="eye mr-1" :size="15"/>

                </b-link>

                <b-dropdown
                    no-caret
                    toggle-class="p-0"
                    variant="link"
                    menu-class="action-dropdown"
                    dropleft
                >
                    <template #button-content>
                        <RtIcon variant="light" icon="ellipsis-vertical align-middle text-body ml-1" :size="18"/>
                    </template>
                    <b-dropdown-item
                        :to="{
                                      name: 'edit-building',
                                      params: { id: data.item.BuildingID },
                                    }">
                        <RtIcon variant="light" icon="edit" />
                        <span class="align-middle ml-50">{{ i18nT(`Modifier`) }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="onArchive(data.item)"
                    >
                        <RtIcon variant="light" icon="box-archive"/>
                        <span class="align-middle ml-50">
                                        {{listMode === 'active' ? i18nT(`Archiver`) : i18nT(`Désarchiver`)}}
                                    </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="onDelete(data.item)">
                        <RtIcon variant="light" icon="trash text-danger"/>
                        <span
                            class="align-middle ml-50 text-danger"
                        >{{
                                i18nT(`Supprimer`)
                            }}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>


        </b-table>
            <div class="mx-1 mb-2">
            <b-row v-if="buildings.length > 0">
                <b-col
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    cols="12"
                    sm="4"
                >
                    <b-form-checkbox  v-model="selectAllStatus" class="d-inline-block mx-1"
                        @change="onSelectAll"
                    >
                        {{ i18nT(`Tout`) }}
                    </b-form-checkbox>
                    <b-dropdown
                        variant="outline-secondary"
                        size="sm"
                    >
                        <template #button-content>
                            <RtIcon variant="light" icon="download"/>
                            {{ i18nT(`Export`) }}
                        </template>

                        <b-dropdown-item class="text-primary"
                        >
                            <div class="d-flex align-items-center">
                                <RtIcon variant="light" icon="file-excel"/>
                                <span class="pl-1">
                                {{ i18nT(`Export format Excel`) }}
                                    </span>
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item
                        >
                            <div class="d-flex align-items-center">
                                <RtIcon variant="light" icon="file-lines"/>
                                <span class="pl-1">{{ i18nT(`Export format OpenOffice`) }}</span>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col  cols="12" sm="4" class="d-flex align-items-center justify-content-center">
                    <span class="d-none d-md-flex text-muted ml-1">
                        {{ i18nT(`Affichage de`) }} {{ dataMeta.from }} {{ i18nT(`à`) }}
                        {{ dataMeta.to }} {{ i18nT(`sur`) }} {{ dataMeta.of }}
                        {{ i18nT(`entrées`) }}
                    </span>
                </b-col>
                <!-- Pagination -->
                <b-col
                    class="
                        d-flex
                        align-items-center
                        justify-content-center justify-content-sm-end
                      "
                    cols="12"
                    sm="4"
                >
                    <b-pagination
                        v-model="currentPage"
                        :per-page="perPage"
                        :total-rows="totalBuildings"
                        class="mb-0 mt-1 mt-sm-0"
                        first-number
                        last-number
                        next-class="next-item"
                        prev-class="prev-item"
                    >
                        <template #prev-text>
                            <RtIcon variant="light" icon="chevron-left" :size="16"/>
                        </template>
                        <template #next-text>
                            <RtIcon variant="light" icon="chevron-right" :size="16"/>
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
        </b-card>
        <b-card no-body v-if="selectedItems.length>0" class="float-actions">
            <b-row class="">
                <b-col cols="12"
                       class="d-flex m-1 "
                        >
                    <span class="d-none d-md-flex align-items-center mr-1">
                    {{i18nT(`Actions`)}}
                        </span>
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        @click="onBulkDelete"
                        v-b-tooltip.hover = "i18nT(`Supprimer la sélection`)"
                    >
                        <RtIcon :size="16" variant="light" icon="trash"/>
                    </b-button>
                    <b-button
                        variant="outline-secondary"
                        class="ml-1"
                        size="sm"
                        @click="onBulkArchive"
                        v-b-tooltip.hover = "i18nT(`Archiver la sélection`)"

                    >
                        <RtIcon :size="16" variant="light" icon="box-archive"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>

</template>

<script>

import useBuildingsList from './tableBuildingsList'
import useAuth from '@/auth/useAuth'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from '@/store/index'


import {
    VBTooltip,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BLink, BFormRadioGroup, BFormGroup,
} from "bootstrap-vue";

export default {
    components: {
        BFormGroup, BFormRadioGroup,
        BCard,
        BRow,
        BCol,
        BTable,
        BFormCheckbox,
        BDropdown,
        BDropdownItem,
        BLink,
        vSelect
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            currentItems: [],
            selectedItems: [],
            selectAllStatus: false,
            availableFields: [
                {label: this.i18nT(`Immeuble`), key: 'BuildingAddress', visible: true},
                {label: this.i18nT(`Superficie`), key: 'BuildingSize', visible: true},
                {label: this.i18nT(`Lots`), key: 'PropertiesCount', visible: true},
                {label: this.i18nT(`Description`), key: 'BuildingComments', visible: true}
            ],
        }
    },
    setup() {
        const {
            fetchBuildings,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refBuildingsListTable,
            totalBuildings,
            listMode,
            buildings,
            activeBuildingsCount,
            archiveBuildingsCount,
            refetchData
        } = useBuildingsList()

        return {
            fetchBuildings,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refBuildingsListTable,
            totalBuildings,
            listMode,
            buildings,
            activeBuildingsCount,
            archiveBuildingsCount,
            refetchData
        }
    },
    computed: {
        computedColumns() {

            let col = [...this.tableColumns]

            for(let i = 0; i < this.availableFields.length; i++) {
                if(!this.availableFields[i].visible) {
                    col = col.filter(c => c.key !== this.availableFields[i].key)
                }
            }
            useAuth.setColumnPreferences('buildings', this.availableFields);

            return col;
        },
        emptyHtml() {
            let imgPath = store.state.appConfig.layout.skin === 'dark'

            return "<img src='" + require(imgPath ? '@client/images/errorDark.svg' : '@client/images/error.svg')
                + "' alt='Error page' class='img-fluid' width='300'><br/><h2>"
                + this.i18nT(`Il n'y a rien par ici ...`) + "</h2><p>"
                + this.i18nT(`Cette page permet de gérer vos immeubles. Renseignez les tantièmes de chaque bien, afin de ventiler les dépenses communes..`) + "</p>"
                +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/" +"create-building/general-info\"'>" + this.i18nT(`Nouvel immeuble`) + "</button>"
        },
    },
    methods: {
        onTableRefresh() {
            this.selectedItems = []
        },
        onSelectAll(val) {
          if (val) {
              this.selectedItems = this.currentItems.map(item => item.BuildingID)
          } else {
              this.selectedItems = []
          }
        },
        onDelete(item) {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Supprimer définitivement l'immeuble?`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if(result.value) {
                    this.$http.delete(`landlord/buildings?id=${item.BuildingID}`)
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Succès`),
                            text: this.i18nT(`Supprimé avec succès`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                    .catch((err) => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Supprimer définitivement l'immeuble?`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result=>{
                if(result.value){
                    this.$http.post(`landlord/buildings/MassDelete?ids=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false;
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$swal({
                                icon: 'error',
                                title: 'Error!',
                                text: this.errorMessage(err),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false;
                            this.refetchData()
                        })
                }
            })


        }, 
        onArchive(item) {

            const type = this.listMode === 'active' ? 'archive' : 'unarchive'

            this.$http.post(`landlord/buildings/${type}?id=${item.BuildingID}`)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Item was successfully archived`),
                        icon: 'CoffeeIcon',
                        variant: 'success',
                    }
                })
                this.refetchData()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        onBulkArchive() {

            const type = this.listMode === 'active' ? 'MassArchive' : 'MassUnarchive'

            this.$http.post(`landlord/buildings/${type}?ids=${this.selectedItems.join(',')}`)
            .then(() => {
                this.selectedItems = []
                this.selectAllStatus = false
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Items was successfully archived`),
                        icon: 'CoffeeIcon',
                        variant: 'success',
                    }
                })
                this.refetchData()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            })
        },
    }
}



</script>

<style lang="scss"> 
    @import '@core/scss/vue/libs/vue-select.scss';


</style>